(function($) {
    $(window).ready(function () {

        if (getCookie('cookie_message') != 'accepted') {
            $('body').prepend('<div class="cc-cookies " style="top: auto; bottom: 0px;">Utilizamos cookies propias y de terceros con fines analíticos y para mejorar nuestros servicios. Si continúas navegando, consideramos que aceptas su uso. Puedes cambiar la configuración u obtener más información <a href="/cookies" title="política de cookies">aquí</a>.  <a href="#accept" class="cc-cookie-accept" data-action="close">Aceptar cookies</a></div>');
            var $cookies_message = $('.cc-cookies');
            $cookies_message.on('click', 'a[data-action="close"]', function (e) {
                e.preventDefault();
                setCookie('cookie_message', 'accepted', 90);
                $cookies_message.fadeOut(300);
            });
        }
    });


    // cookies management
    function setCookie(c_name,value,exdays) {
        var exdate=new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
        document.cookie=c_name + "=" + c_value;
    }
    function getCookie(c_name) {
        var i,x,y,ARRcookies=document.cookie.split(";");
        for (i=0;i<ARRcookies.length;i++)
        {
            x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
            y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
            x=x.replace(/^\s+|\s+$/g,"");
            if (x==c_name)
            {
                return unescape(y);
            }
        }
        return "";
    }
})(jQuery);






