// assets/js/app.js

require('../css/app.css');
require('../css/bootstrap.min.css');
require('../css/pwdwidget.css');
require('../css/font-awesome.min.css');
require('../css/cookiecuttr.css');
require('../css/animate.min.css');
require('../css/estilos.css');
require('../css/datepicker.css');
require('../css/datepicker3.css');
require('../css/dataTables.bootstrap.css');
require('../css/jquery.smartsuggest.css');
require('../css/cardValidate.css');

// const $ = require('./jquery-1.12.4.min');
const $ = require('jquery');
window.jQuery = $;
window.$ = $;
global.jQuery = $;
global.$ = $;
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
// require('./bootstrap.min');
require('bootstrap');
require('./jquery.validate.min');
require('./cookies.min');
require('./jquery.placeholder.min');
require('./additional-methods.min');
require('./jquery.dataTables.min');
require('./dataTables.date-eu');
require('./pdfmake.min');
require('./vfs_fonts');
require('./jszip.min');
require('./buttons.html5.min');
require('./jquery.smartsuggest');
require('./scriptsLayoutv2');
global.Fingerprint2 = require('./fingerprint');